/** Ends and Odds: Spoons **/

import React from "react";
import { gsap } from "gsap"
import { preloadImages, scaleFullscreen, frameAnimation } from "../../helpers"
import PrevNext from "../../components/navigation/prevnext"
import SEO from "../../components/seo"
import { showText, hideText, positionText, resetAnimationDelay } from "../../helpers"
import Carousel from "../../components/media/carousel";
import SlideshowController from "../../components/media/slideshow-controller";

let globalState, data, preloadData, slideshowData, spoonBounceTL, autoAdvanceInterval;

class EndsAndOddsPage1 extends React.Component {

  constructor(props) {
    super(props);

    //update global state and assets data
    globalState = this.props.location.state;
    data = globalState.assets[1];
    preloadData = globalState.assets[2];

    this.timeout1 = null;
    this.timeout2 = null;

    slideshowData = [
      {
        file: globalState.videoUrl + data.slide1,
        poster: globalState.cdnUrl + data.slide1Poster,
        fileType: 'video'
      },
      {
        file: globalState.cdnUrl + data.slide2,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide3,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide4,
        fileType: 'image',
      },
      {
        file: globalState.cdnUrl + data.slide5,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide6,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide7,
        fileType: 'image'
      },
      {
        file: globalState.videoUrl + data.slide8,
        poster: globalState.cdnUrl + data.slide8Poster,
        fileType: 'video'
      },
      {
        file: globalState.cdnUrl + data.slide9,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide10,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide11,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide12,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide13,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide14,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide15,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide16,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide17,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide18,
        fileType: 'image'
      }
    ]

    //refs
    this.fullscreenBg = null;
  }

  state = {
    textVisible: false,
    currentSlide: 1,
    autoAdvance: 4000
  }



  componentDidMount = () => {

    setTimeout(() => {
      this.refs.carouselRef.playVideos();
    }, globalState.transitionDuration);

    //position text
    positionText(this.refs.text1Ref, "right", "top", globalState.textPosition.rightTop);


    //show text
    gsap.to(this.refs.text1Ref, 0.15, { autoAlpha: 1.0 });

    //add base track
    //globalState.baseAudioRef.updateTrack(1, globalState.cdnUrl + data.soundfile1);

    //Update dimensions
    this.updateDimensions();

    //3. Animate in Spoon
    this.timeout1 = setTimeout(function () {
      gsap.to(this.spoonRef, 0, { rotate: 90, scale: 1.2, autoAlpha: 1.0 });
      frameAnimation(this.spoonRef, 58, 211, 10, 10, 93, 0.4, 0);
    }.bind(this), 1000);

    this.timeout2 = setTimeout(function () {
      //gsap.to(this.spoonBounceRef, 0.1, { autoAlpha: 1.0 });
      //frameAnimation(this.spoonRef, 211, 58, 10, 10, 93, 0.2, 0);
      spoonBounceTL = gsap.timeline();
      //spoonBounceTL.to(this.spoonRef, {rotate: 0, x: "-=230", y: "-=200", duration: 1});
      //spoonBounceTL.to(this.spoonRef, {rotate: 0, x: "-=0", y: "+=220", duration: 1});
      spoonBounceTL.to(this.spoonRef, {rotate: 72, x: "-=19", y: "-=19", duration: 0.05});
      spoonBounceTL.to(this.spoonRef, {rotate: 63, x: "-=25", y: "-=21", duration: 0.05});
      spoonBounceTL.to(this.spoonRef, {rotate: 54, x: "-=21", y: "-=18", duration: 0.05});
      spoonBounceTL.to(this.spoonRef, {rotate: 45, x: "-=21", y: "-=26", duration: 0.05});
      spoonBounceTL.to(this.spoonRef, {rotate: 36, x: "-=23", y: "-=8", duration: 0.05});
      spoonBounceTL.to(this.spoonRef, {rotate: 27, x: "-=21", y: "-=18", duration: 0.05});
      spoonBounceTL.to(this.spoonRef, {rotate: 18, x: "-=51", y: "-=20", duration: 0.1});
      spoonBounceTL.to(this.spoonRef, {rotate: 9, x: "-=35", y: "-=20", duration: 0.2});
      spoonBounceTL.to(this.spoonRef, {rotate: 0, x: "-=18", y: "-=12", duration: 0.05});
      spoonBounceTL.to(this.spoonRef, {rotate: 6, x: "+=2", y: "+=9", duration: 0.05});
      spoonBounceTL.to(this.spoonRef, {rotate: 0, x: "-=10", y: "+=10", duration: 0.05});
      spoonBounceTL.to(this.spoonRef, {rotate: -3, x: "+=4", y: "+=10", duration: 0.05});
      spoonBounceTL.to(this.spoonRef, {rotate: -3, x: "+=5", y: "+=11", duration: 0.05});
      spoonBounceTL.to(this.spoonRef, {rotate: -3, x: "+=7", y: "+=11", duration: 0.05});
      spoonBounceTL.to(this.spoonRef, {rotate: 0, x: "-=2", y: "+=11", duration: 0.05});
      spoonBounceTL.to(this.spoonRef, {rotate: 0, x: "-=0px", y: "+=126", duration: 0.4});
      
    }.bind(this), 5000);

    //Add resize listener
    window.addEventListener("resize", this.updateDimensions);

    //Preload assets from next page
    preloadImages([
      globalState.cdnUrl + preloadData.text1, 
      globalState.cdnUrl + preloadData.text2, 
      globalState.cdnUrl + preloadData.bgVideoPoster], 
      this.preloadCallback);

    //update the carousel 
    this.refs.carouselRef.updateCurrentSlide(this.state.currentSlide);

  }

  preloadCallback = () => {
    this.refs.prevNextRef.showPrevNextBtns(globalState.showPrevNextDelay);
  }



  componentDidUpdate() {
    this.refs.carouselRef.updateCurrentSlide(this.state.currentSlide);
  }


  componentWillUnmount() {

    //Kill any tweens
    gsap.killTweensOf(this.refs.text1Ref);

    window.removeEventListener("resize", this.updateDimensions);


    //Clear AutoAdvanceInterval
    clearInterval(autoAdvanceInterval);

    //remove base track
    //globalState.baseAudioRef.updateTrack(1, false);
  }



  toggleTextDisplay = (event) => {

    //toggle text visibility
    this.setState({ textVisible: !this.state.textVisible });

    //reset the animation delay
    resetAnimationDelay();

    if (this.state.textVisible) {
      //show text
      showText(this.refs.text1Ref, "right", "top", globalState.textPosition.rightTop);


    } else {
      //hide text
      hideText(this.refs.text1Ref, "right");

    }
  }

  isTransitioning = () => {
    //this.roadVideoRef.pauseVideo();
    this.refs.carouselRef.pauseVideos();
  }

  updateDimensions = () => {
    console.log('updateDimensions');
    //background native size is 1288 x 779
    scaleFullscreen(this.refs.fullscreenBgRef, window.innerWidth, window.innerHeight, 1288, 779, false);
  }

  //Slideshow controls
  handleNextClick = () => {
    let nextSlide = this.state.currentSlide + 1;

    if (nextSlide > slideshowData.length) {
      nextSlide = 1;
    }

    this.setState({ currentSlide: nextSlide });
    this.refs.carouselRef.pauseVideos();
    if(slideshowData[nextSlide-1].fileType === "video"){
      this.refs.carouselRef.playVideo(nextSlide);
    }
    this.stopAutoAdvance();

  }
  handleBackClick = () => {
    let nextSlide = this.state.currentSlide - 1;

    if (nextSlide < 1) {
      nextSlide = slideshowData.length;
    }

    this.setState({ currentSlide: nextSlide });
    this.refs.carouselRef.pauseVideos();
    if(slideshowData[nextSlide-1].fileType === "video"){
      this.refs.carouselRef.playVideo(nextSlide);
    }
    this.stopAutoAdvance();
  }

  updateCurrentSlide = (num) => {

    for (let i = 1; i <= slideshowData.length; i++) {
      this.refs['slide' + i].classList.remove('show');
      if (i === this.state.currentSlide) {
        this.refs['slide' + i].classList.add('show');
      }
    }
  }

  //Stop AutoAdvance
  stopAutoAdvance = () => {
    this.setState({ autoAdvance: false });
    clearInterval(autoAdvanceInterval);
  }

  //Set AutoAdvance
  setAutoAdvance = () => {
    if (this.state.autoAdvance && !autoAdvanceInterval) {
      autoAdvanceInterval = setInterval(() => {
        let nextSlide = this.state.currentSlide + 1;

        if (nextSlide > slideshowData.length) {
          nextSlide = 1;
        }
        this.setState({ currentSlide: nextSlide });
        this.refs.carouselRef.pauseVideos();
        if(slideshowData[nextSlide-1].fileType === "video"){
          this.refs.carouselRef.playVideo(nextSlide);
        }
      }, this.state.autoAdvance);
    }
  }

  videoEnded = (e) => {
    console.log(e);
    console.log("Video Ended: " + this.state.currentSlide);
    if(this.state.currentSlide === 1){
      //Set AutoAdvance
      this.setAutoAdvance();
    }
    
  }

  render() {

    return (

      <>

        <link rel="prefetch" href="/ends-and-odds/2" />
        <link rel="preload" as="video" href={globalState.videoUrl + preloadData.bgVideo} />

        <PrevNext globalState={globalState} ref="prevNextRef"
          nextLocation="/ends-and-odds/2" prevLocation="/ends-and-odds"
          isTransitioning={this.isTransitioning} />
        {data && (
        <div className="fullpage-wrapper">
          <SEO title="Pine Point - Ends and Odds" />

          <div className="text-wrapper">
            <div className="text text--right-top" ref="text1Ref">
              <input type="image" src={globalState.cdnUrl + data.text1} style={{ marginRight: "2vw", marginTop: "0" }} onClick={this.toggleTextDisplay} alt="After the infrastructure is built and the systems put in place, people move in, go to work, go to school, become best friends, get married, have children, hold funerals. That planed town becomes a community. Q. How long does a town have to be around before you make a souvenir spoon with its name on it? A. Longer than it takes for people to make the spoon rack, apparently." />
            </div>
          </div>
          <SlideshowController xPos={"calc(100% - 520px)"} yPos="390px" handleNextClick={this.handleNextClick} handleBackClick={this.handleBackClick} state={this.state} totalSlides={slideshowData.length} />
          <div className="fullscreen-bg" ref="fullscreenBgRef">
            <div className="spoon" ref={div => this.spoonRef = div} alt="Spoon" style={{ width: "58px", height: "211px", left: "1010px", top: "480px", backgroundImage: `url(${globalState.cdnUrl + data.spoon})` }} />
            <div className="spoonsCarouselWrapper" style={{width: "515px", height:"427px", left:"150px", top:"70px"}}>
              <Carousel ref="carouselRef" data={slideshowData} globalState={globalState} fullscreen={false} position={['0', '0']} size={["515px", "427px"]} state={this.state} ended={this.videoEnded} />
            </div>
            <img src={globalState.cdnUrl + data.background} alt="background" className="background spoons-bg" />
          </div>

        </div>
        )}
      </>
    );
  }


};

export default EndsAndOddsPage1
