import React from "react"


const SlideshowController = ({ xPos, yPos, handleNextClick, handleBackClick, state, totalSlides }) => {

   

    return (
        <div className="slideshow-controller" style={{left: xPos, top: yPos}}>
            <div className="tape" />
            <div className="controller-text">{state.currentSlide}/{totalSlides}</div>
            <button className="arrow arrow--back" onClick={handleBackClick} />
            <button className="arrow arrow--next" onClick={handleNextClick} />
        </div>

    )
}

export default SlideshowController