import React from "react"

//import { gsap } from "gsap"
import FullscreenVideo from "./fullscreen-video";


let slides;
//let this.props.data;
let isFullscreen, fullscreenClass;
let isLoop = true;
let carouselX, carouselY, carouselW, carouselH;

class Carousel extends React.Component {

    
    componentDidMount() {
        this.updateCurrentSlide(this.props.state.currentSlide);
    }

    pauseVideo = (num) => {
        this.refs['video' + num].pauseVideo();
    }
    playVideo = (num) => {
        this.refs['video' + num].playVideo(0);
    }
    
    pauseVideos = () => {
        let slideType;
        for (let i = 1; i <= this.props.data.length; i++) {
            slideType = this.props.data[i-1].fileType;
            console.log("pauseVideos: " + slideType);
            
            if (slideType === "video") {
                //this.refs['slide' + i].getElementsByTagName("video").pauseVideo();
                this.refs['video' + i].pauseVideo();
            }
        }
    }

    playVideos = () => {
        let slideType;
        for (let i = 1; i <= this.props.data.length; i++) {
            slideType = this.props.data[i-1].fileType;
            console.log("pauseVideos: " + slideType);
            
            if (slideType === "video") {
                //this.refs['slide' + i].getElementsByTagName("video").pauseVideo();
                this.refs['video' + i].playVideo();
            }
        }
    }


    updateCurrentSlide = (num, overrideCurrentState = false) => {
        console.log("updateCurrentSlide: " + num)
        console.log("this.props.data.length: " + this.props.data.length);

        for (let i = 1; i <= this.props.data.length; i++) {
            this.refs['slide' + i].classList.remove('show');
            if(overrideCurrentState && i === num){   
                //override currentSlide state property
                this.refs['slide' + i].classList.add('show');
            } else if (i === this.props.state.currentSlide) {
                this.refs['slide' + i].classList.add('show');
            }
        }
    }

    render() {
        
        if(this.props.fullscreen) {
            isFullscreen = "fullscreen";
            fullscreenClass = "fullscreen-video";
            carouselX = 0;
            carouselY = 0;
        } else {
            isFullscreen = "";
            fullscreenClass = "";
            carouselX = this.props.position[0]; 
            carouselY = this.props.position[1]; 
            carouselW = this.props.size[0]; 
            carouselH = this.props.size[1];
        }

        if (this.props.ended) {
            isLoop = false;
        }

        console.log("isLoop: " + isLoop);
        
        //this.props.data.map
        slides = this.props.data.map((slide, i) => {
            if (slide.fileType === 'video') {
                return <li key={i} ref={"slide" + (i+1)}><FullscreenVideo ref={"video"+(i+1)} file={slide.file} autoPlay={false} muted={false} loop={isLoop} poster={slide.poster} fullscreenClass={fullscreenClass} ended={this.props.ended} /></li>
            } else {
                return <li key={i} ref={"slide" + (i+1)} style={{ backgroundImage: `url(${slide.file})` }}>
                    <div className="credit">{slide.credit}</div>
                </li>
            }
        }
        );

        return (
            
            <>
                <ul className={`carousel ${isFullscreen}`} style={{left: carouselX, top: carouselY, width: carouselW, height: carouselH}}>
                    {slides}
                </ul>
            </>
        )
    }
}

export default Carousel
